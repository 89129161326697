<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-5);
      }

      .cls-8 {
        fill: url(#inox-gradient-6);
      }
      
      .cls-10 {
        fill: url(#inox-gradient-7);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }

      .cls-12 {
        fill: url(#glass-pattern);
      }

      .cls-13 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 50.76" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 90.72" 
      :y2="doorTopHeight1 + 150.25" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 50.76" 
      :y1="doorTopHeight1 + 210.17" 
      :x2="doorLeftWidth1 + 90.72" 
      :y2="doorTopHeight1 + 210.17" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 51.21" 
      :y1="doorTopHeight1 + 120.29" 
      :x2="doorLeftWidth1 + 65.19" 
      :y2="doorTopHeight1 + 120.29" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 76.29" 
      :y1="doorTopHeight1 + 120.29" 
      :x2="doorLeftWidth1 + 90.27" 
      :y2="doorTopHeight1 + 120.29" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" 
      :x1="doorLeftWidth1 + 51.21" 
      :y1="doorTopHeight1 + 180.21" 
      :x2="doorLeftWidth1 + 65.19" 
      :y2="doorTopHeight1 + 180.21" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6" data-name="inox" 
      :x1="doorLeftWidth1 + 76.29" 
      :y1="doorTopHeight1 + 180.21" 
      :x2="doorLeftWidth1 + 90.27" 
      :y2="doorTopHeight1 + 180.21" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="handle-gradient" 
      :x1="doorLeftWidth1 + 11.29" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 16.93" 
      :y2="doorTopHeight1 + 150.25" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-7" data-name="inox" 
      :x1="doorLeftWidth1 + 50.76" 
      :y1="doorTopHeight1 + 90.33" 
      :x2="doorLeftWidth1 + 90.72" 
      :y2="doorTopHeight1 + 90.33" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 103.31" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 77.34" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 163.23" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 137.27" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 223.15" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 197.19" 
      xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="S18">
      <polyline id="inox" 
                v-if="showInox" 
                data-name="inox" 
                class="cls-3" 
                :points="`${doorLeftWidth1 + 50.77} ${doorTopHeight1 + 150.25} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 170.22} ${doorLeftWidth1 + 90.72} ${doorTopHeight1 + 150.25} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 130.28} ${doorLeftWidth1 + 50.77} ${doorTopHeight1 + 150.25}`"/>
      <polyline id="inox-2" 
                v-if="showInox" 
                data-name="inox" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 190.2} ${doorLeftWidth1 + 50.77} ${doorTopHeight1 + 210.17} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 230.14} ${doorLeftWidth1 + 90.72} ${doorTopHeight1 + 210.17} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 190.2}`"/>
      <polyline id="inox-3"
                v-if="showInox"  
                data-name="inox" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 51.22} ${doorTopHeight1 + 120.29} ${doorLeftWidth1 + 58.2} ${doorTopHeight1 + 127.28} ${doorLeftWidth1 + 65.19} ${doorTopHeight1 + 120.29} ${doorLeftWidth1 + 58.2} ${doorTopHeight1 + 113.3} ${doorLeftWidth1 + 51.22} ${doorTopHeight1 + 120.29}`"/>
      <polyline id="inox-4" 
                v-if="showInox" 
                data-name="inox" 
                class="cls-6" 
                :points="`${doorLeftWidth1 + 83.29} ${doorTopHeight1 + 127.28} ${doorLeftWidth1 + 90.27} ${doorTopHeight1 + 120.29} ${doorLeftWidth1 + 83.29} ${doorTopHeight1 + 113.3} ${doorLeftWidth1 + 76.29} ${doorTopHeight1 + 120.29} ${doorLeftWidth1 + 83.29} ${doorTopHeight1 + 127.28}`"/>
      <polyline id="inox-5" 
                v-if="showInox" 
                data-name="inox" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 58.2} ${doorTopHeight1 + 173.22} ${doorLeftWidth1 + 51.22} ${doorTopHeight1 + 180.21} ${doorLeftWidth1 + 58.2} ${doorTopHeight1 + 187.2} ${doorLeftWidth1 + 65.19} ${doorTopHeight1 + 180.21} ${doorLeftWidth1 + 58.2} ${doorTopHeight1 + 173.22}`"/>
      <polyline id="inox-6" 
                v-if="showInox" 
                data-name="inox" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 90.27} ${doorTopHeight1 + 180.21} ${doorLeftWidth1 + 83.29} ${doorTopHeight1 + 173.22} ${doorLeftWidth1 + 76.29} ${doorTopHeight1 + 180.21} ${doorLeftWidth1 + 83.29} ${doorTopHeight1 + 187.2} ${doorLeftWidth1 + 90.27} ${doorTopHeight1 + 180.21}`"/>
      <polyline id="inox-7" 
                v-if="showInox" 
                data-name="inox" 
                class="cls-10" 
                :points="`${doorLeftWidth1 + 50.77} ${doorTopHeight1 + 90.33} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 110.31} ${doorLeftWidth1 + 90.72} ${doorTopHeight1 + 90.33} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 70.36}`"/>
      <line id="inox-8" data-name="inox" class="cls-2" :x1="`${doorLeftWidth1 + 50.76}`" :y1="`${doorTopHeight1 + 90.33}`" :x2="`${doorLeftWidth1 + 70.74}`" :y2="`${doorTopHeight1 + 70.36}`"/>
      <polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-11" 
                :points="`${doorLeftWidth1 + 57.76} ${doorTopHeight1 + 90.33} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 103.31} ${doorLeftWidth1 + 83.73} ${doorTopHeight1 + 90.33} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 77.34} ${doorLeftWidth1 + 57.76} ${doorTopHeight1 + 90.33}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" 
                class="cls-12" 
                :points="`${doorLeftWidth1 + 57.76} ${doorTopHeight1 + 150.25} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 163.23} ${doorLeftWidth1 + 83.73} ${doorTopHeight1 + 150.25} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 137.27} ${doorLeftWidth1 + 57.76} ${doorTopHeight1 + 150.25}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3" 
                data-name="glass" 
                class="cls-13" 
                :points="`${doorLeftWidth1 + 57.76} ${doorTopHeight1 + 210.17} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 223.15} ${doorLeftWidth1 + 83.73} ${doorTopHeight1 + 210.17} ${doorLeftWidth1 + 70.74} ${doorTopHeight1 + 197.19} ${doorLeftWidth1 + 57.76} ${doorTopHeight1 + 210.17}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
